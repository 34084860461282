import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { removeQuestionBank, getQuestionBank, getQuestionBankByFolder } from "@actions"
import { LoaderText } from "@utils";
// import WrapModalEdit from './WrapModalEdit';
import { useHistory } from 'react-router-dom';

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import { SearchCustomForTable } from "@ui-partents/Search";
import { DateFormatter } from "@ui-partents/DateTime";
// import { Rating } from 'react-simple-star-rating'

//Icon
import { Icon } from '@components/common';
import More from '@images/teachV2/more-vertical.svg';
import { TagsToolTip } from "@ui-partents/Tags";

// Import the pagination configuration
import {paginationOptions} from "@utils";
import { WrapModalSend } from './ModalSendForReview';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { teachV2Constants } from "@constants";
import { WrapModalMoveToFolder } from './ModalMoveToFolder';

const TableMcqBank = (props) => {
  const { setListValue, inputValue, setInputValue, listValue, activeFolder } = props;
  const history = useHistory()
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getQuestionBank || []);

  const getListMyQuestionBank = () => {
    if (!activeFolder?.FolderId) {
      dispatch(getQuestionBank())
    } else {
      dispatch(getQuestionBankByFolder({
        Action: "LoadFolder",
        Mode: "MyQuestionBank",
        FolderId: activeFolder?.FolderId
      }))
    }
  }

  const handleRemove = async (idRemove) => {
    const params = {
      Action: "Delete",
      QuestionItemId: idRemove,
    };
    const result = await dispatch(removeQuestionBank(params));
    if (result?.data?.status === 200) {
      setListValue([])
      getListMyQuestionBank()
    }
  };

  // const handleShareReference = async (data) => {
  //   const dataShare = await dispatch(shareMediaOrg({
  //     Action: "ShareToOrganization",
  //     QuestionItemId: data?.QuestionItemId
  //   }))
  //   if (dataShare?.data?.status === 200) {
  //     toast.success('Successfully shared to Organization Question Bank', {
  //       position: "bottom-right",
  //       autoClose: 4000,
  //       hideProgressBar: false,
  //       closeOnClick: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     getListMyQuestionBank()
  //     setListValue([])
  //   }
  // }

  // const handleUnshareReference = async (data) => {
  //   const dataShare = await dispatch(shareMediaOrg({
  //     Action: "UnShareToOrganization",
  //     QuestionItemId: data?.QuestionItemId
  //   }))
  //   if (dataShare?.data?.status === 200) {
  //     toast.success('Successfully shared to Organization Question Bank', {
  //       position: "bottom-right",
  //       autoClose: 4000,
  //       hideProgressBar: false,
  //       closeOnClick: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     getListMyQuestionBank()
  //     setListValue([])
  //   }
  // }

  useEffect(() => {
    getListMyQuestionBank()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFolder])

  useEffect(() => {
    if (listQuestionBank?.data || listValue?.length > 0) {
      dispatch({ type: teachV2Constants.FILTER_QUESTION_BANK_SUCCESS, payload: listQuestionBank?.data, params: listValue });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValue])

  const componentsCommon = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    // if (value?.length === 0) {
    //   dispatch(getQuestionBank())
    // }
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Question",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "QuestionType",
      text: "Type",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center question-type ${cell === "MCQ" ? "type-mcq" : ""}`}>
            <span></span>
            <p>
              {cell}
            </p>
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      Field: "tags",
      text: "Tag(s)",
      headerClasses: "align-middle text-center",
      headerStyle: { width: "168px" },
      formatter: (cell, row) => {
        return (
          <TagsToolTip
            tags={row?.Tags}
            documentId={row?.QuestionItemId}
            color={'blue'}
          />
        );
      },
    },
    {
      dataField: "ReviewStatus",
      text: "Status",
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center question-type ${cell === "Under Review" ? "type-under" : cell === "Review Completed" ? "" : "type-default"}`}>
            <p>
              {cell}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "CreatedAt",
      text: "Date Created",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <DateFormatter date={cell} format="DD/MM/YYYY, h:mm:ss A" />
        );
      },
    },
    // {
    //   dataField: "AverageStar",
    //   text: "Rating",
    //   headerStyle: { width: "190px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <Rating 
    //         readonly={true} 
    //         initialValue={cell} 
    //         allowFraction
    //         size={28}
    //       />
    //     );
    //   },
    // },
    // {
    //   dataField: "QuestionType",
    //   text: "Status",
    //   headerStyle: { width: "190px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <TagsText content={cell}/>
    //     );
    //   },
    //   filterValue: (cell, row) => row.Contents.question
    // },
    // {
    //   dataField: "",
    //   text: "Action",
    //   headerClasses: "text-center",
    //   headerStyle: { width: "50px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="d-flex align-items-center justify-content-center action-reference">
    //         <Icon src={More}/>
    //         <div className="action-reference__list">
    //           {row?.status?.color !== "warning" && (
    //             !row?.SharedToOrganization ? (
    //               <p onClick={() => handleShareReference(row)}>Share to Organization</p>
    //             ) : (
    //               <p onClick={() => handleUnshareReference(row)}>Unshare</p>
    //             )
    //           )}
    //           <WrapModalEdit
    //             dataEdit={row}
    //           >
    //             {({onOpen}) => (
    //               <p onClick={row?.Rubric?.instruction_text 
    //                 ? () => handleQuestionDetail(row?.QuestionItemId)
    //                 : onOpen}
    //               >
    //                 Edit
    //               </p>
    //             )}
    //           </WrapModalEdit>
    //           <WrapModalDeleteCommon
    //             title={`Delete Question`}
    //             sub={`Are you sure you want to delete this question? This action cannot be undone.`}
    //             handleRemove={handleRemove}
    //             idRemove={row?.QuestionItemId}
    //           >
    //             {({onOpen}) => (
    //               <p onClick={onOpen}>Delete</p>
    //             )}
    //           </WrapModalDeleteCommon>
    //         </div>
    //       </div>
    //     );
    //   },
    // }
    {
      dataField: "",
      text: "Action",
      headerClasses: "text-center",
      headerStyle: { width: "50px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-reference">
            <Icon src={More}/>
            <div className="action-reference__list">
              {!row?.Rubric?.instruction_text && (
                <WrapModalSend
                  dataEdit={row}
                  setListValue={setListValue}
                  getListMyQuestionBank={getListMyQuestionBank}
                >
                  {({onOpen}) => (
                    <p onClick={onOpen}>Send for Review</p>
                  )}
                </WrapModalSend>
              )}
              <p onClick={() => {
                const questionDetail = JSON.stringify(row)
                if (row?.Rubric?.instruction_text) {
                  history.push({
                    pathname: `/teach/free-text/${row?.QuestionItemId}`,
                    state: {  // location state
                      listBank: true,
                    },
                  })
                } else {
                  if (row?.ReviewStatus === "Not Yet Submitted") {
                    history.push({
                      pathname: `/teach/question-bank/edit`,
                      state: {  // location state
                        question: questionDetail, 
                        path: "/teach/inventory/mcq"
                      },
                    })
                  } else {
                    history.push({
                      pathname: `/teach/question-bank/edit`,
                      state: {  // location state
                        question: questionDetail, 
                        type: "review",  
                        path: "/teach/inventory/mcq"
                      },
                    })
                  }
                }
              }}>
                View/Edit
              </p>
              <WrapModalMoveToFolder
                dataEdit={row}
                getListOrgBank={getListMyQuestionBank}
                {...props}
              >
                {({onOpen}) => (
                  <p onClick={onOpen}>Move to Folder</p>
                )}
              </WrapModalMoveToFolder>
              <WrapModalDeleteCommon
                title={`Delete Question`}
                sub={`Are you sure you want to delete this question? This action cannot be undone.`}
                handleRemove={handleRemove}
                idRemove={row?.QuestionItemId}
              >
                {({onOpen}) => (
                  <p onClick={onOpen} className="action-reference__list-last">Delete</p>
                )}
              </WrapModalDeleteCommon>
            </div>
          </div>
        );
      },
    }
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={listQuestionBank?.dataFilter || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {listQuestionBank?.dataFilter?.length}</div>
              <div className='d-flex align-items-center'>
                <div className="boxSearch">
                  <SearchCustomForTable
                    toolkitprops={toolkitprops}
                    paginationProps={paginationProps}
                    isResetPagination
                    searchMode="auto"
                    placeholder={`Search`}
                  />
                </div>
                <div className="email-share__form-input ml-2">
                  <CreatableSelect
                    components={componentsCommon}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                      handleAddTag(newValue)
                    }
                    }
                    onInputChange={(newValue) => {
                      setInputValue(newValue)
                    }}
                    onKeyDown={(e) => {
                      paginationProps.onPageChange(1, 10);
                      handleKeyDown(e)
                    }}
                    onBlur={handleBlur}
                    placeholder="Filter by Tags"
                    value={listValue}
                    classNamePrefix={`filter-tag`}
                  />
                </div>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4 pb-5"
              bordered={false}
              data={listQuestionBank?.dataFilter || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listQuestionBank?.dataFilter?.length || 0;  // Determine the total size dynamically
  // Generate pagination configuration dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        listQuestionBank?.dataFilter?.length === 0 && listValue?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableMcqBank
