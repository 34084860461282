import React, { useCallback, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import PulseLoader from "react-spinners/PulseLoader";

// Icons
import IconMicro from "@images/chatvoice/micro-icon.svg";
import IconPause from "@images/chatvoice/pause-icon.svg";
import VoiceDisabled from "@images/chatvoice/voice_disabled.svg";

//redux
import { useSelector } from "react-redux";

// Audio
import AudioClick from "@audio/select-click.wav";

const pulse = keyframes`
  0% { transform: scale(0); opacity: 0; border: 30px solid #2268F0; }
  50% { opacity: 0.8; border: solid #2268F0; }
  90% { transform: scale(2.5); opacity: 0.2; border: 2px solid #2268F0; }
  100% { transform: scale(2.6); opacity: 0; border: 1px solid #2268F0; }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 80px;
  ${({ isLoading }) => isLoading && css`pointer-events: none;`}

  .object {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .outline {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 20px solid #2268f0;
    position: absolute;
    ${({ isRecording }) => isRecording && css`animation: ${pulse} 2s ease-out infinite;`}
  }

  .button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #2268f0;
    opacity: 0.34;
    cursor: pointer;
    transition: transform 0.2s;
    &:active {
      transform: scale(0.95);
    }
    ${({ isRecording }) => isRecording && css`pointer-events: none;`}
  }

  .button-circle-in {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #2268f0;
    position: absolute;
  }

  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
`;

const LoaderWrapper = styled.div`
  margin-top: 10px;
`;

const PulseButtonAnimate = (props) => {
  //props
  const { isRecording, onButtonClick, statusGenerateAudio, audioStarted, isLoading } = props && props;
  console.log(isLoading, "isLoading...");

  //Click Audio
  const clickAudio = React.useMemo(() => new Audio(AudioClick), []);

  // Redux state
  const { data: questionData, isLoading: questionLoading } = useSelector((state) => state.question || {});
  const missingTaskStatus = useSelector((state) => (state?.checkMissingTask) || {});
  const isLoadingMissingTask = missingTaskStatus?.isLoading;

  // Modify the isProcessing condition to include the missing task loading state
  const isProcessing = statusGenerateAudio === "streaming" || questionLoading || isLoadingMissingTask || isLoading?.submitQuestion || isLoading?.nextQuestion;
  const isPlaying = statusGenerateAudio === "playing";
  const notMultipleChoice = questionData?.Question?.Type !== "multiple_choice";

  useEffect(() => {
    clickAudio.load();
  }, [clickAudio]);

  const handleClick = useCallback(() => {
    if (!isPlaying) {
      clickAudio.play();
      onButtonClick();
    }
  }, [isPlaying, onButtonClick, clickAudio]);

  if (isProcessing) {
    return (
      <Box isLoading={isProcessing}>
        <LoaderWrapper>
          <PulseLoader color="#2268F0" size={12} />
        </LoaderWrapper>
        <Text>Thinking...</Text>
      </Box>
    );
  }

  return (
    notMultipleChoice && statusGenerateAudio !== "playing" && (
      <Box
        isRecording={isRecording}
        isLoading={statusGenerateAudio === "playing"}
        onClick={handleClick}
      >
        <div className="object">
          <div className="outline" />
          <div className="button" />
          <div className="button button-circle-in" />
          <div className="icon-wrapper">
            <img
              src={isPlaying ? VoiceDisabled : isRecording ? IconPause : IconMicro}
              alt={isPlaying ? "Voice Disabled" : isRecording ? "Stop Recording" : "Start Recording"}
              className="icon"
            />
          </div>
        </div>
        <Text>{isRecording || audioStarted ? "" : "Press to Talk"}</Text>
      </Box>
    )
  );
};

export default PulseButtonAnimate;