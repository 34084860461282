const convertDataReactSelect = (data) => {
  let listOptions = [];
  data && data.map(function (item) {
      const dataReturn = { value: item.Email, label: item.Email};
      return listOptions.push(dataReturn);
    });
    return listOptions;
}

const formatDate = (inputDate) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const dateParts = inputDate.split(", ");
  const [month, day, year] = dateParts[0].split("/");
  const [hour, minute, second] = dateParts[1].split(":");

  const date = new Date(year, month - 1, day, hour, minute, second);
  
  const formattedDate = `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}, ` +
                        `${date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}`;
  
  return formattedDate;
}

export {
  convertDataReactSelect,
  formatDate,
};