import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'
import { ResultQuestionBank } from './ResultQuestionBank'
import { FeedbackAndRating } from './FeedbackAndRating'
import { ListTags } from './ListTagsQuestion'
// import { FooterFinalBank } from './FooterQuestionBank'
// import FooterQuestionBank from './FooterQuestionBank/FooterQuestionBank'

const WrapTagsAndFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  hr {
    margin: 0;
  }
`

export default function QuestionBankEditView(props) {
  const { type } = props

  return (
    <div className="result-top">
      <Row>
        <Col md={8}>
          <ResultQuestionBank {...props}/>
        </Col>
        <Col md={4}>
          <WrapTagsAndFeedback>
            <ListTags
              text="edit"
              {...props}
            />
            <hr />
            {type === "org" && <FeedbackAndRating {...props}/>}
          </WrapTagsAndFeedback>
        </Col>
      </Row>
      {/* {type === "org" && permission && (
        <div id="result-bottom">
          <Container>
            <Row>
              <Col md={12}>
                <FooterFinalBank  {...props}/>
              </Col>
            </Row>
          </Container>
        </div>
      )} */}
    </div>
  )
}
