import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import styled, { keyframes } from "styled-components";

//Scrollbar
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//imgPerson
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

//context
import { DeviceContext } from "@context/DeviceContext";

//Icon
import IconNotSupport from "@images/access-mic/not-support-voice.svg";

// Components
import { MicrophoneMessage } from "@ui-partents/Message";

// Free Text
import { InputFreeText } from "../Preceptor/MutipleChoice";


//components
import {
  ScrollableMessages,
  MutipleChoiceView,
} from "@components/learner/StandardScreen/Chat/Preceptor";
import {
  ButtonNextQuestion,
  MessageBoxEndChat,
} from "@components/learner/StandardScreen/Chat/Preceptor";

//UserActive
import { UserActive } from "../../Chat";

//lodash
import _ from "lodash";

//constants
import { questionConstants } from "@constants";

//actions
import {
  atcCallLockElementsChat,
  setTypeMicrophoneInstructor,
} from "@actions";

//Stream
import { StreamingComponent } from "@components/learner/StandardScreen/Chat/InstructorStream";

//STT
import { InstructorChatSTTMain } from "@components/learner/StandardScreen/Chat/InstructorVoice";

// Keyframes for fadeInUp animation
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled component for the fade-in effect
const FadeInWrapper = styled.div`
  animation: ${fadeInUp} 0.6s ease-in-out;
`;

function Framechat(props) {
  //props
  const {
    messagesEndRef,
    handleUpdateRecord,
    isCurrentTask,
    handleFetchInstruction,
    showUserActive,
    ChatMode
  } = props && props;
  let { id } = useParams();
  const dispatch = useDispatch();

  //Case - Reducer
  const caseReducer = useSelector((state) => state.cases || []);
  const caseResponse = caseReducer?.data?.Case;
  let statusMessageChatVoice = useSelector(
    (state) => state?.statusBrowserForMicrophone?.isActive
  );
  let FirstTimePoint = caseResponse?.Timeline?.TimePointNum === 1;

  //Check Mode
  let InstructorChatMode = caseResponse?.InstructorChatMode;

  //Question Reducer
  const questionData = useSelector((state) => state.question || {});
  let QuestionResponse = questionData?.data?.Question;
  let TaskToResume = questionData?.data?.TaskToResume || null;
  let QuestionTypeCurrent = QuestionResponse?.Type;
  let SubType = QuestionResponse?.SubType;
  const mutipleChoiceData = QuestionResponse?.AnswerChoices;
  let isFreeText = QuestionTypeCurrent === "free_text";

  //context
  const { device, deviceType } = useContext(DeviceContext); // Get device and device type from context
  const [modalMessageIOS, setModalMessageIOS] = useState(false);
  const [isLoadQuestionNext, setLoadQuestionNext] = useState(false);
  const [isStatusVoice, setStatusVoice] = useState(null);
  const [loadViewChatVoice, setLoadViewChatVoice] = useState(false);

  // Ref for textarea
  const textareaRef = useRef(null);

  // Auto-focus textarea on mount
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get height bottom of chat
  const divRef = useRef(null);
  const [heightFooterChat, setheightFooterChat] = useState(0);
  useEffect(() => {
    if (divRef?.current) {
      // Sử dụng offsetHeight để lấy chiều cao của div
      const height = divRef.current.offsetHeight;
      setheightFooterChat(height);
    }
  }, [questionData]);

  //state
  const [optionsData, setOptionsData] = useState({
    SelectMany: mutipleChoiceData || null,
    SelectOne: null,
  });
  const [nextQuestion, setNextQuestion] = useState(false);
  const [valueOptions, setvalueOptions] = useState(null);
  const [questionId, setQuestionId] = useState(QuestionResponse?.Id || null);
  const [isLoading, setLoading] = useState({
    submitQuestion: false,
    nextQuestion: false,
    streamData: false,
  });

  //---------stream Data-------//
  const [contentStream, setStream] = useState(null);
  let intervalIDRef = useRef(null);

  // State Load Question Id
  useEffect(() => {
    const QuestionResponse = questionData?.data?.Question;
    setQuestionId(QuestionResponse?.Id || null);
    handleScrollBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  //Them Property ExplanationText cho array
  useEffect(() => {
    const QuestionResponse = questionData?.data?.Question;
    const SubType = QuestionResponse?.SubType;
    const AnswerChoices = QuestionResponse?.AnswerChoices;

    //Khi load page check xem có statement hay không để hiện button I got It
    // Các điều kiện kiểm tra
    const isInstructorChatModeText = InstructorChatMode === 'text';
    const hasExplanation = QuestionResponse?.Explanation;
    const isStatementType = QuestionResponse?.Type === "statement";
    // Hiển thị nút I got it để người dùng click đi tiếp (Free_Text);
    // Dựa vào Explanation hoặc Type để kết thúc câu hỏi hiện tại
    if (isInstructorChatModeText && (hasExplanation || isStatementType)) {
      setNextQuestion(true);
    }

    // Format Data
    const AddPropertyToArray = (data) => {
      const newData = data?.map((value) => ({
        ...value,
        ExplanationText: "",
        Selected: false,
      }));

      if (SubType === "select_many") {
        setOptionsData((prevState) => ({
          ...prevState,
          SelectMany: newData || [],
        }));
      } else if (SubType === "select_one") {
        setOptionsData((prevState) => ({
          ...prevState,
          SelectOne: newData || [],
        }));
      }
    };
    AnswerChoices?.length > 0 && AddPropertyToArray(AnswerChoices || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  // stream Data
  const handleStreamData = (questionPromise) => {
    // Neu questionPromise chua co ket qua tra ve thi sau 3s goi api nay
    Promise.race([
      questionPromise,
      new Promise((resolve) => setTimeout(resolve, 3000, "timeout")),
    ]).then((result) => {
      if (result !== "timeout") {
        console.log("API question returned before timeout");
        return;
      }

      console.log("API question timed out. Calling API stream_data...");
      intervalIDRef.current = setInterval(() => {
        setLoading((prevState) => ({ ...prevState, streamData: true }));
        apiCaller(`/api/learn/case/stream_data/${id}/`, "GET").then((res) => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setStream(dataStream);
            setLoading((prevState) => ({ ...prevState, streamData: false }));

            //Scroll Bottom
            handleScrollBottom();
          } else {
            setStream(null);
            setLoading((prevState) => ({ ...prevState, streamData: false }));

            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        });
      }, 1000);
    });
  };

  //Scroll Bottom
  const handleScrollBottom = () => {
    messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
  };

  //Handle Change Mutiple Choice
  const handleSelectMany = (data, e) => {
    const { value, checked, name } = e.target;
    const newDataChoices = [...optionsData?.SelectMany];
    const getAttributeName = e?.target?.getAttribute("data-name");
    let index = newDataChoices.findIndex((value) => value.Id === data.Id);

    if (getAttributeName === "ExplanationText") {
      newDataChoices[index]["ExplanationText"] = value;
    }
    if (name === "Selected") {
      newDataChoices[index]["Selected"] = checked;
    }

    // Show data for input
    displaySelectedItemsInInput(newDataChoices);

    //setData
    setOptionsData((prevState) => ({
      ...prevState,
      SelectMany: newDataChoices || [],
    }));
  };

  const displaySelectedItemsInInput = (newDataChoices) => {
    const selectedItems = newDataChoices
      .filter((item) => item.Selected)
      .map((item) => item.Content)
      .join(", ");
    setvalueOptions(selectedItems);
  };

  // Handle Select One
  const handleSelectOne = (data, e) => {
    const { value, checked, name } = e.target;
    const newDataChoices = [...optionsData?.SelectOne];

    if (name === "Selected") {
      let index = newDataChoices.findIndex((value) => value.Id === data.Id);

      // Kiểm tra xem đã có một phần tử nào có giá trị "Selected" là true chưa
      const hasTrueSelected = newDataChoices.some(
        (choice) => choice.Selected === true
      );

      // Nếu đã có một phần tử nào có giá trị "Selected" là true, hãy đặt giá trị của tất cả các phần tử khác thành false
      if (hasTrueSelected) {
        newDataChoices.forEach((choice) => {
          choice.Selected = false;
        });
      }

      // Đặt giá trị "Selected" của phần tử hiện tại thành giá trị của checked
      newDataChoices[index]["Selected"] = checked;
    }

    if (name === "ExplanationText") {
      let index = newDataChoices.findIndex((value) => value.Id === data.Id);
      newDataChoices[index]["ExplanationText"] = value;
    }

    // Show data for input
    displaySelectedItemsInInput(newDataChoices);

    //set data
    setOptionsData((prevState) => ({
      ...prevState,
      SelectOne: newDataChoices || [],
    }));
  };

  // Clear Data
  function clearMultipleChoiceData(optionsData, SubType, setOptionsData) {
    const newData = { ...optionsData };
    if (SubType === "select_many") {
      newData.SelectMany = null;
    } else if (SubType === "select_one") {
      newData.SelectOne = null;
    }
    setOptionsData(newData);
  }

  // Hanlde Push Data to reducer
  const pushDataToReducerForUser = async (data, type) => {
    //Push data vao store
    const selectedItems = _.chain(data)
      .filter((item) => item.Selected)
      .map((item) => item.Content)
      .join(", ")
      .value();
    if (type === "multiple_choice") {
      // multiple_choice
      await handleUpdateRecord(selectedItems, type, "User");
    } else {
      // free_text
      await handleUpdateRecord(data, type, "User");
    }
  };

  // Handle Submit Question
  const resetLoadingAndMessage = () => {
    setLoading((prevState) => ({ ...prevState, submitQuestion: false }));
  };

  // Function xử lý kết thúc câu hỏi hiện tại
  const handleEndCurrentQuestion = (currentQsData, QuestionTypeCurrent) => {
    // Các điều kiện kiểm tra
    const isInstructorChatModeText = InstructorChatMode === 'text';
    const hasExplanation = currentQsData?.Explanation;
    const isStatementType = currentQsData?.Type === "statement";

    // Hiển thị nút I got it để người dùng click đi tiếp (Free_Text);
    // Dựa vào Explanation hoặc Type để kết thúc câu hỏi hiện tại
    if (isInstructorChatModeText && (hasExplanation || isStatementType)) {
      setNextQuestion(true);
    }

    // Update câu hiện tại vào chat record (Voice, Free_Text)
    handleUpdateRecord(currentQsData, QuestionTypeCurrent, "Instructor");
  }

  // Hàm lấy câu trả lời cho câu hỏi dựa trên loại câu hỏi
  const getAnswerForQuestion = (QuestionTypeCurrent, SubType, optionsData, AnswerInput) => {
    switch (QuestionTypeCurrent) {
      case "multiple_choice":
        return SubType === "select_many" ? optionsData?.SelectMany : optionsData?.SelectOne;
      case "free_text":
        return AnswerInput;
      default:
        return "";
    }
  };

  // Hàm gửi API để submit câu hỏi và xử lý kết quả
  const submitQuestionAPI = async (params) => {
    const res = await apiCaller("/api/learn/question/submit/", "POST", params);
    if (res?.status !== 200) {
      throw new Error("Failed to submit question");
    }
    return res?.data;
  };

  // Hàm reset tất cả trạng thái và dọn dẹp dữ liệu sau khi câu hỏi được xử lý
  const resetAfterSubmit = (QuestionTypeCurrent, optionsData, SubType, setOptionsData, setStream, intervalIDRef, setvalueOptions, textareaRef) => {
    // Reset loading và message
    resetLoadingAndMessage();

    // Dọn dẹp dữ liệu nếu là câu hỏi trắc nghiệm
    if (QuestionTypeCurrent === "multiple_choice") {
      clearMultipleChoiceData(optionsData, SubType, setOptionsData);
    }

    // Dừng stream data và reset timeout
    setStream(null);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;

    // Reset dữ liệu nhập vào
    setvalueOptions(null);

    // Focus lại vào textarea
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  // Hàm xử lý sau khi nhận được câu trả lời từ API
  const handleResponseData = (Question, QuestionTypeCurrent) => {
    if (Question === null) {
      handleNextQuestion(); // Nếu question null thì kiểm tra xem còn câu hỏi không?
    } else {
      handleEndCurrentQuestion(Question, QuestionTypeCurrent);
    }

    // Cập nhật reducer
    dispatch({
      type: questionConstants.QUESTION_SUBMIT,
      payload: { Question },
    });
  };
  
  // Hàm chính để xử lý submit câu hỏi
  const handleSubmitQuestion = useCallback(
    (AnswerInput = null) => {
      return new Promise(async (resolve, reject) => {
        try {
          // Trước khi gọi API, khóa view
          dispatch(atcCallLockElementsChat(true));
  
          // Cập nhật trạng thái loading
          setLoading((prevState) => ({ ...prevState, submitQuestion: true }));
          handleScrollBottom();
  
          const paramsAnswer = getAnswerForQuestion(QuestionTypeCurrent, SubType, optionsData, AnswerInput);
  
          const params = {
            CaseId: id,
            QuestionId: questionId,
            QuestionType: QuestionTypeCurrent,
            Answer: paramsAnswer,
          };
  
          // Push Data vào reducer cho người dùng
          await pushDataToReducerForUser(paramsAnswer, QuestionTypeCurrent);
  
          // Gọi API để submit câu hỏi
          const resData = await submitQuestionAPI(params);
  
          // Lấy dữ liệu câu trả lời từ API
          const { QuestionResponse: Question } = resData;
  
          // Xử lý câu trả lời và chuyển sang câu hỏi tiếp theo
          handleResponseData(Question, QuestionTypeCurrent);
  
          // Reset và dọn dẹp dữ liệu sau khi submit
          resetAfterSubmit(QuestionTypeCurrent, optionsData, SubType, setOptionsData, setStream, intervalIDRef, setvalueOptions, textareaRef);
  
          // Xử lý dữ liệu stream
          handleStreamData(Promise.resolve(resData));
  
          // Resolve kết quả
          resolve(resData);
        } catch (error) {
          // Xử lý lỗi
          reject(error);
        } finally {
          
          // Mở khóa view sau khi xử lý xong
          dispatch(atcCallLockElementsChat(false));

          // Reset trạng thái loading và thông báo nếu cần
          resetLoadingAndMessage();
          
          //Scroll Bottom
          handleScrollBottom();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, id, questionId, optionsData, QuestionTypeCurrent, SubType, pushDataToReducerForUser]
  );  

  //Call api Next Question (Khi Click Got It) - Hoặc click nút next (stream)
  const handleNextQuestion = async () => {
    // Trước khi gọi API, khóa view
    dispatch(atcCallLockElementsChat(true));
    setLoading((prevState) => ({ ...prevState, nextQuestion: true }));
    try {
      setLoadQuestionNext(true);
      dispatch({ type: questionConstants.QUESTION_REQUEST, isLoading: true });
  
      const res = await apiCaller(
        `/api/learn/question/next/${id}/?task=${isCurrentTask}`,
        "GET",
        null
      );
  
      if (res.status === 200) {
        const data = res?.data;
        const Type = data?.Question?.Type;
        const qsResponse = data?.Question;
        const resQuestionNull = data?.Question === null;
  
        handleUpdateRecord(qsResponse, Type, "Instructor");
  
        // Set new Question Id
        setQuestionId(data?.Question?.Id);
        dispatch({
          type: questionConstants.QUESTION_SUCCESS,
          payload: data,
          isLoading: false,
        });
  
        // Nếu câu hỏi null, kiểm tra Instruction
        if (resQuestionNull && isCurrentTask) {
          await handleFetchInstruction(id, isCurrentTask);
        }
  
        // Ẩn trạng thái loading
        setNextQuestion(false);
  
      } else {
        dispatch({
          type: questionConstants.QUESTION_FAILURE,
          error: "error",
          isLoading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching next question:", error);
      dispatch({
        type: questionConstants.QUESTION_FAILURE,
        error: error.message,
        isLoading: false,
      });
    } finally {
      // Sau khi xử lý API, mở khóa view
      dispatch(atcCallLockElementsChat(false));
      setLoading((prevState) => ({ ...prevState, nextQuestion: false }));
  
      // Dọn dẹp trạng thái loading và focus lại vào textarea
      setLoadQuestionNext(false);
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };
  
  // Hiển thị modal khi là Safari trên iOS hoặc macOS chỉ một lần
  useEffect(() => {
    const isIOSOrMacOSSafari = () => {
      const userAgent = navigator.userAgent;

      // Kiểm tra iOS
      const isIOS = /iPhone|iPad|iPod/.test(userAgent);

      // Kiểm tra Safari trên macOS
      const isSafariOnMac =
        /Macintosh/.test(userAgent) &&
        /Safari/.test(userAgent) &&
        !/Chrome/.test(userAgent);

      return isIOS || isSafariOnMac;
    };

    if (
      isIOSOrMacOSSafari() &&
      isFreeText &&
      !statusMessageChatVoice &&
      FirstTimePoint
    ) {
      setModalMessageIOS(true);
      dispatch(setTypeMicrophoneInstructor(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, deviceType, isFreeText, statusMessageChatVoice, FirstTimePoint]);

  //Check Status Stream TTS (Voice)
  const handleTTSStreamFinally = (status) => {
    //Trường hợp này xảy ra khi load và submit question.
    // Hàm này sẽ luôn load khi audio phát completed.
    // const playerAudioDone = isStatusVoice === 'ended';
    const isCompleted = status === 'completed';
    const hasExplanation = QuestionResponse?.Explanation;
    const isStatementType = QuestionResponse?.Type === "statement";
    const shouldSetNextQuestion = isCompleted && (hasExplanation || isStatementType);
    // Only trigger when TTS is completed and the conditions are met
    if (shouldSetNextQuestion) {
      setNextQuestion(true);
    }
  }

  //Characters
  const Characters = caseResponse?.Characters;
  const Instructor = Characters?.Instructor;
  const InstructorCharacter = Instructor?.Character;
  const preceptorNameText = Instructor?.DisplayName;
  const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;

  // Khi Chat Voice đang xử lý
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  //Check Missing Task
  const missingTaskStatus = useSelector((state) => (state?.checkMissingTask) || {});
  const IsLoadingMissingTask = missingTaskStatus?.isLoading;
  const DoneAPI = !isLoading?.submitQuestion && !IsLoadingMissingTask;
  const IsMutipleChoice = QuestionTypeCurrent === "multiple_choice";

  //Điều kiện show mutiple choice (Dùng chung cho free_text, voice)
  // Nếu InstructorChatMode không phải "voice", thì không cần kiểm tra isStatusVoice
  const isVoiceConditionMet = InstructorChatMode !== "voice" || isStatusVoice === 'ended';
  const ConditionShowMutipleChoice = !TaskToResume && !nextQuestion && InstructorChatMode !== "stream" && DoneAPI && isVoiceConditionMet && IsMutipleChoice;

  // Chờ khi submitQuestion xong và kết thúc phát audio mới show nút next (Nếu InstructorChatMode là voice )
  const shouldDisplayNextButton = InstructorChatMode === "voice" 
  ? nextQuestion && !TaskToResume && !isLoading?.submitQuestion && isStatusVoice === 'ended'
  : nextQuestion && !TaskToResume && !isLoading?.submitQuestion;

  //Nếu là mode Voice thì phải check thêm loadViewChatVoice để tránh gây giật view TaskToResume
  const isVoiceMode = InstructorChatMode === "voice" && loadViewChatVoice;
  const ShowTaskResume = TaskToResume && DoneAPI && !isLock && !nextQuestion && (isVoiceMode || InstructorChatMode !== "voice");
  return (
    <React.Fragment>
      {modalMessageIOS &&
        <MicrophoneMessage
          title={`Notice About Voice Feature`}
          content={`This case supports Voice-based chat, but the feature is currently unavailable on iOS devices. You can still interact using Text. To explore the Voice feature, please use a desktop, laptop, or Android device. We apologize for the inconvenience and are actively working on a solution. All other features are fully accessible!`}
          isOpen={modalMessageIOS}
          icon={IconNotSupport}
          onClose={() => setModalMessageIOS(false)} // Đóng modal khi người dùng tắt
        />
      }
      <div
        className={`frameChat__fullBox interact_panel position-relative scrollbarStyle`}
        // Tránh bị giật scroll
        style={{overflowY: InstructorChatMode === "voice" ? 'auto' : 'unset'}} 
        data-type={InstructorChatMode}
      >
        {InstructorChatMode === "voice" &&
          <InstructorChatSTTMain
            {...props}
            ChatMode={ChatMode}
            InstructorChatMode={InstructorChatMode}
            nextQuestion={nextQuestion}
            handleSubmitQuestion={handleSubmitQuestion}
            handleStatusChangeForVoice={(statusVoice) => setStatusVoice(statusVoice)}
            TaskToResume={TaskToResume}
            handleTTSStreamFinally={handleTTSStreamFinally}
            isLoading={isLoading}
            handleLoadInstructorChatDone={(statusDone) => setLoadViewChatVoice(statusDone)}
          />
        }
        {InstructorChatMode === "stream" &&
          <StreamingComponent
            {...props}
            handleNextQuestion={handleNextQuestion}
            isLoadQuestionNext={isLoadQuestionNext}
          />
        }
        {InstructorChatMode === "text" &&
          <React.Fragment>
            {showUserActive && (
              <UserActive
                userNameActive={preceptorNameText}
                RoleDefault={`Preceptor`}
                image={PreceptorAvatar}
              />
            )}
            <ScrollableMessages
              {...props}
              messagesEndRef={messagesEndRef}
              isLoading={isLoading}
              contentStream={contentStream}
              heightFooterChat={heightFooterChat}
              nextQuestion={nextQuestion}
            />
            {QuestionTypeCurrent === "free_text" && (
              <InputFreeText
                {...props}
                handleSubmitQuestion={handleSubmitQuestion}
                IsloadingSubmitAnswer={isLoading?.submitQuestion}
                valueOptions={valueOptions}
                typeQuestion={QuestionTypeCurrent}
                disabled={QuestionTypeCurrent === "multiple_choice"}
              />
            )}
          </React.Fragment>
        }

        {/* Sử dụng cho voice và free_text, 2 style khác nhau */}
        {ConditionShowMutipleChoice &&
          <div ref={divRef} type="MutipleChoiceView">
            <MutipleChoiceView
              {...props}
              divRef={divRef}
              nextQuestion={nextQuestion}
              TaskToResume={TaskToResume}
              SubType={SubType}
              Type={QuestionTypeCurrent}
              isLoading={isLoading}
              QuestionResponse={QuestionResponse}
              optionsData={optionsData}
              handleSelectMany={handleSelectMany}
              handleSelectOne={handleSelectOne}
              handleSubmitQuestion={handleSubmitQuestion}
              valueOptions={valueOptions}
              textareaRef={textareaRef}
              InstructorChatMode={InstructorChatMode}
              // Khoá button đến khi nào phát xong mới cho chọn mutiple choice
              DisabledBtnSubmitMutipleChoice={isStatusVoice !== 'ended'}
            />
          </div>
        }

        {/* Apply fadeInUp animation to MessageBoxEndChat and TaskToResume */}
        {ShowTaskResume && (
          <FadeInWrapper type="MessageBoxEndChat">
            <MessageBoxEndChat {...props} TaskToResume={TaskToResume} />
          </FadeInWrapper>
        )}

        {shouldDisplayNextButton && (
          <FadeInWrapper>
            <ButtonNextQuestion
              {...props}
              handleNextQuestion={handleNextQuestion}
              isLoading={questionData?.isLoading?.nextQuestion}
            />
          </FadeInWrapper>
        )}
      </div>
    </React.Fragment>
  );
}

export default Framechat;