import React from 'react'
import { Col, Row } from 'reactstrap'
// import { ResultQuestionBank } from './ResultQuestionBank'
// import { FeedbackAndRating } from './FeedbackAndRating'
import { FormFeedbackAndRating } from './FormFeedbackAndRating'
// import FooterQuestionBank from './FooterQuestionBank/FooterQuestionBank'
// import { FormQuestion } from './FormAnswerQuestion'
import styled from 'styled-components'
import { ListTags } from './ListTagsQuestion'
import { ResultQuestionBank } from './ResultQuestionBank'

const WrapTagsAndFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D6DBE3; 
    border-radius: 8px;
  }

  hr {
    margin: 0;
  }
`

const WrapResult = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  padding-right: 8px;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D6DBE3; 
    border-radius: 8px;
  }
`

export default function QuestionBankReview(props) {
  // const { permission } = props

  return (
    <div className="result-top">
      <Row>
        <Col md={8}>
          {/* <FormQuestion {...props}/> */}
          <WrapResult>
            <ResultQuestionBank {...props}/>
          </WrapResult>
        </Col>
        <Col md={4}>
          <WrapTagsAndFeedback>
            <ListTags
              text="review"
              {...props}
            />
            <hr />
            <FormFeedbackAndRating {...props}/>
          </WrapTagsAndFeedback>
        </Col>
      </Row>
      {/* {permission && (
        <div id="result-bottom">
          <Container>
            <Row>
              <Col md={12}>
                <FooterQuestionBank {...props}/>
              </Col>
            </Row>
          </Container>
        </div>
      )} */}
    </div>
  )
}
