import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionBankOrg, sentToFinalBank } from "@actions"
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
import { Rating } from 'react-simple-star-rating'
import { TagsToolTip } from "@ui-partents/Tags";

//Icon
import { Icon } from '@components/common';
import More from '@images/teachV2/more-vertical.svg';

// Import the pagination configuration
import {paginationOptions} from "@utils";
import { useHistory } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { teachV2Constants } from "@constants";
// import { toast } from 'react-toastify';
import { WrapModalMoveToFolder } from './ModalMoveToFolder';
import { toast } from 'react-toastify';



const TableMcqBank = (props) => {
  const { setListValue, inputValue, setInputValue, listValue, isQbankAdmin, activeFolder } = props;
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getQuestionBankOrg || []);
  const history = useHistory()

  const getListOrgBank = () => {
    if (!activeFolder?.FolderId) {
      dispatch(getQuestionBankOrg({
        Action: "LoadOrganizationQuestionBank"
      }))
    } else {
      dispatch(getQuestionBankOrg({
        Action: "LoadFolder",
        Mode: "OrganizationQuestionBank",
        FolderId: activeFolder?.FolderId
      }))
    }
  }

  useEffect(() => {
    getListOrgBank()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFolder])

  useEffect(() => {
    if (listQuestionBank?.data) {
      dispatch({ type: teachV2Constants.FILTER_QUESTION_BANK_ORG_SUCCESS, payload: listQuestionBank?.data, params: listValue });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValue])

  const componentsCommon = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    if (value?.length === 0) {
      getListOrgBank()
    }
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const handleSentFinal = async (data) => {
    const dataShare = await dispatch(sentToFinalBank({
      Action: "SendToFinalBank",
      QuestionItemId: data?.QuestionItemId
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Successfully published to Final Bank', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getListOrgBank()
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Question",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "Owner",
      text: "Owner",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Owner || "med2lab.com"}
          </div>
        );
      },
    },
    {
      Field: "tags",
      text: "Tag(s)",
      headerClasses: "align-middle text-center",
      formatter: (cell, row) => {
        return (
          <TagsToolTip
            tags={row?.Tags}
            documentId={row?.QuestionItemId}
            color={'blue'}
          />
        );
      },
    },
    {
      dataField: "ReviewStatus",
      text: "Status",
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center question-type ${cell === "Under Review" ? "type-under" : cell === "Review Completed" ? "" : "type-default"}`}>
            <p>
              {cell === "Under Review" ? `${cell} (${row?.ReviewData?.length || 0})` : cell}
            </p>
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "AverageStar",
      text: "Rating",
      formatter: (cell, row) => {
        return (
          <Rating 
            readonly={true} 
            initialValue={cell} 
            allowFraction
            size={28}
          />
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "text-center",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-reference">
            <Icon src={More}/>
            <div className="action-reference__list">
              <p 
                onClick={() => {
                  const questionDetail = JSON.stringify(row)
                  history.push({
                    pathname: `/teach/question-bank/edit`,
                    state: {  // location state
                      question: questionDetail, 
                      type: "org",
                      permission: isQbankAdmin,
                      path: "/teach/inventory/mcq/org",
                    },
                  })
                }}
              >
                View Details
              </p>
              <WrapModalMoveToFolder
                dataEdit={row}
                getListOrgBank={getListOrgBank}
                {...props}
              >
                {({onOpen}) => (
                  <p onClick={onOpen}>Move to Folder</p>
                )}
              </WrapModalMoveToFolder>
              {isQbankAdmin && (
                <p  onClick={() => handleSentFinal(row)}>
                  Publish to Final Bank
                </p>
              )}
            </div>
          </div>
        );
      },
    }
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={listQuestionBank?.dataFilter || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {listQuestionBank?.dataFilter?.length}</div>
              <div className='d-flex align-items-center'>
                <div className="boxSearch">
                  <SearchCustomForTable
                    toolkitprops={toolkitprops}
                    paginationProps={paginationProps}
                    isResetPagination
                    searchMode="auto"
                    placeholder={`Search`}
                  />
                </div>
                <div className="email-share__form-input ml-2">
                  <CreatableSelect
                    components={componentsCommon}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                      handleAddTag(newValue)
                    }
                    }
                    onInputChange={(newValue) => {
                      setInputValue(newValue)
                    }}
                    onKeyDown={(e) => {
                      paginationProps.onPageChange(1, 10);
                      handleKeyDown(e)
                    }}
                    onBlur={handleBlur}
                    placeholder="Filter by Tags"
                    value={listValue}
                    classNamePrefix={`filter-tag`}
                  />
                </div>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4 pb-5"
              bordered={false}
              data={listQuestionBank?.dataFilter || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listQuestionBank?.dataFilter?.length || 0; // Determine the total size dynamically
  
  // Retrieve dynamic pagination settings
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        listQuestionBank?.dataFilter?.length === 0 && listValue?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableMcqBank
