import React, { useEffect, useState } from 'react';

//components
import { TableMcqBank, TableMcqBankOrg } from "@components/teachV2/teachMcqBank";
import { TableToBeReviewed } from './TableToBeReviewed';
// import { TableAllReviews } from './AllReviews';
import { useHistory, useLocation } from 'react-router-dom';
import FinalBank from './AllReviews/FinalBank';
import { useDispatch, useSelector } from 'react-redux';
import { getListFolders, getListPermissions } from "@actions"
import { useMemo } from 'react';
import { ListFolderItem } from './AllReviews';

const WrapMcqBank = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = location;
  const Account = JSON.parse(localStorage.getItem("Account"));
  const dataAllFolders = useSelector((state) => state.getListFolders || []);
  const userPermission = useSelector((state) => state.getListPermissions || []);
  const [activeTab, setActiveTab] = useState(pathname === "/teach/inventory/mcq/final" ? 4 : pathname === "/teach/inventory/mcq/review" ? 2 : pathname === "/teach/inventory/mcq/org" ? 3 : 1);
  const [listValue, setListValue] = useState([]);
  const [tabsOption, setTabsOption] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [activeFolder, setActiveFolder] = useState({
    FolderId: 0,
  })

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setListValue([])
    if (tabId === 1) {
      history.push("/teach/inventory/mcq")
    } else if (tabId === 2) {
      history.push("/teach/inventory/mcq/review")
    } else if (tabId === 3) {
      history.push("/teach/inventory/mcq/org")
    } else {
      history.push("/teach/inventory/mcq/final")
    }
  };

  useEffect(() => {
    // Nếu location.state không tồn tại, thay đổi bằng replaceState
    const defaultState = { type: "edit" };
    window.history.replaceState({ ...window.history.state, state: defaultState }, "");
  }, [location]);

  useEffect(() => {
    dispatch(getListFolders({
      Action: "ListFolders"
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dataAllFolders?.data?.length > 0 && setActiveFolder(dataAllFolders?.data[0])
  }, [dataAllFolders?.data])

  const getPermissionsUser = async () => {
    const dataPermission = await  dispatch(getListPermissions({
      Email: Account?.email
    }))
    if (dataPermission?.data?.status === 200) {
      const permissions = dataPermission?.data?.data
      
      setTabsOption(permissions?.some(item => item?.PermissionCode === "qbank_admin") ? [
        { id: 1, key: "my-references", label: "My Question Bank" },
        { id: 2, key: "be-reviewed", label: "To Be Reviewed" },
        { id: 3, key: "org-references", label: "Organization Bank" },
        { id: 4, key: "all-reviews", label: "Final Bank" },
      ] : permissions?.some(item => item?.PermissionCode === "qbank_reviewer") ? [
        { id: 1, key: "my-references", label: "My Question Bank" },
        { id: 2, key: "be-reviewed", label: "To Be Reviewed" },
        { id: 3, key: "org-references", label: "Organization Bank" },
      ] : [
        { id: 1, key: "my-references", label: "My Question Bank" },
        // { id: 2, key: "be-reviewed", label: "To Be Reviewed" },
        // { id: 3, key: "org-references", label: "Organization Bank" },
      ])
    }
  }

  useEffect(() => {
    getPermissionsUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isQbankAdmin = useMemo(() => {
    return userPermission?.data?.some(item => item?.PermissionCode === "qbank_admin")
  }, [userPermission])

  const isQbankReviewer = useMemo(() => {
    return userPermission?.data?.some(item => item?.PermissionCode === "qbank_reviewer")
  }, [userPermission])
  
  return (
    <div className="wrapper -ReferenceLiblaryPage p-0">
      <div className='mcq-bank__tabs'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab !== 2 && (    
          <ListFolderItem
            activeFolder={activeFolder}
            setActiveFolder={setActiveFolder}
            listFolders={dataAllFolders?.data || []}
          />
        )}
        {activeTab === 1 && (
          <TableMcqBank 
            listValue={listValue}
            setListValue={setListValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
            activeFolder={activeFolder}
            listFolders={dataAllFolders?.data || []}
          />
        )}
        {activeTab === 2 && (
          <TableToBeReviewed 
            listValue={listValue}
            setListValue={setListValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
            isQbankAdmin={isQbankAdmin}
            isQbankReviewer={isQbankReviewer}
          />
        )}
        {activeTab === 4 && (
          <FinalBank
            listValue={listValue}
            setListValue={setListValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
            activeFolder={activeFolder}
            listFolders={dataAllFolders?.data || []}
          />
        )}
        {activeTab === 3 && (
          <TableMcqBankOrg 
            listValue={listValue}
            setListValue={setListValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
            isQbankAdmin={isQbankAdmin}
            activeFolder={activeFolder}
            listFolders={dataAllFolders?.data || []}
          />
        )}
      </div>
    </div>
  )
}

export default WrapMcqBank
